<template>
    <v-dialog
        :retain-focus="false"
        v-model="show"
        persistent
        max-width="500px"
        :fullscreen="$vuetify.breakpoint.smAndDown"
    >
        <v-card :loading="loading">
            <v-card-title class="text-h5">
                <v-btn
                    class="mr-3"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="closeDialog"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>

                Send Quote</v-card-title
            >
            <v-card-text>
                <v-form ref="sendQuote" v-model="valid" lazy-validation>
                    <v-row class="align-end my-2" no-gutters>
                        <v-col>
                            <v-combobox
                                v-model="model"
                                :filter="filter"
                                :hide-no-data="!search"
                                :items="usersToPDF"
                                hide-details
                                :search-input.sync="search"
                                hide-selected
                                label="To:"
                                multiple
                                small-chips
                                required
                                flat
                            >
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-chip color="grey lighten-3" small>
                                            {{ search }}
                                        </v-chip>
                                    </v-list-item>
                                </template>
                                <template
                                    v-slot:selection="{
                                        attrs,
                                        item,
                                        parent,
                                        selected,
                                    }"
                                >
                                    <v-chip
                                        v-if="item === Object(item)"
                                        v-bind="attrs"
                                        color="grey lighten-3"
                                        :input-value="selected"
                                        small
                                    >
                                        <span class="pr-2">
                                            {{ item.text }}
                                        </span>
                                        <v-icon
                                            small
                                            @click="parent.selectItem(item)"
                                        >
                                            mdi-close-circle
                                        </v-icon>
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ index, item }">
                                    <v-text-field
                                        v-if="editing === item"
                                        v-model="editing.text"
                                        autofocus
                                        flat
                                        background-color="transparent"
                                        hide-details
                                        solo
                                        @keyup.enter="edit(index, item)"
                                    ></v-text-field>
                                    <v-chip v-else color="grey lighten-3" small>
                                        {{ item.text }}
                                    </v-chip>
                                    <v-spacer></v-spacer>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row class="align-end my-4" no-gutters>
                        <v-col class="">
                            <v-combobox
                                v-model="model1"
                                :filter="filter"
                                :hide-no-data="!search"
                                :items="usersToPDF"
                                :search-input.sync="search"
                                hide-selected
                                hide-details=""
                                label="Cc:"
                                multiple
                                small-chips
                                required
                                flat
                            >
                                <template v-slot:no-data>
                                    <v-list-item>
                                        <v-chip color="grey lighten-3" small>
                                            {{ search }}
                                        </v-chip>
                                    </v-list-item>
                                </template>
                                <template
                                    v-slot:selection="{
                                        attrs,
                                        item,
                                        parent,
                                        selected,
                                    }"
                                >
                                    <v-chip
                                        v-if="item === Object(item)"
                                        v-bind="attrs"
                                        color="grey lighten-3"
                                        :input-value="selected"
                                        small
                                    >
                                        <span class="pr-2">
                                            {{ item.text }}
                                        </span>
                                        <v-icon
                                            small
                                            @click="parent.selectItem(item)"
                                        >
                                            mdi-close-circle
                                        </v-icon>
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ index, item }">
                                    <v-text-field
                                        v-if="editing === item"
                                        v-model="editing.text"
                                        autofocus
                                        flat
                                        background-color="transparent"
                                        hide-details
                                        solo
                                        @keyup.enter="edit(index, item)"
                                    ></v-text-field>
                                    <v-chip v-else color="grey lighten-3" small>
                                        {{ item.text }}
                                    </v-chip>
                                    <v-spacer></v-spacer>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                    <v-row class="align-end mt-8" no-gutters>
                        <v-col class="">
                            <v-text-field
                                v-model="email.subject"
                                label="Subject:"
                                dense
                                :rules="[v => !!v || 'Required']"
                                required
                            />
                        </v-col>
                    </v-row>
                    <v-row class="mt-4" no-gutters>
                        <v-col class="">
                            <v-textarea
                                flat
                                solo
                                placeholder="Message"
                                rows="4"
                                auto-grow
                                dense
                                v-model="email.text"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                    <v-row class="align-end my-2" no-gutters>
                        <v-col v-if="!resend">
                            <span class="font-weight-bold">
                                Quote:
                            </span>
                            <v-btn
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1"
                            >
                                <span
                                    class="text-decoration-underline"
                                    @click="generateAndLoading"
                                    >{{ this.fileName }}</span
                                >
                            </v-btn>
                        </v-col>
                        <v-col v-else>
                            <span class="font-weight-bold">
                                Quote:
                            </span>
                            <v-btn
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1"
                            >
                                <span
                                    class="text-decoration-underline"
                                    @click="
                                        openFilePDF(
                                            docsBySend[docsBySend.length - 1]
                                        )
                                    "
                                    >{{
                                        docsBySend[docsBySend.length - 1]
                                    }}</span
                                >
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col v-if="!resend" class="flex-wrap">
                            <span class="font-weight-bold">Attached:</span>

                            <v-btn
                                v-for="file in quote.attached"
                                :key="file.file"
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1  "
                            >
                                <span
                                    class="text-decoration-underline"
                                    @click="openFile(file.file)"
                                    >{{ file.name }}</span
                                >
                            </v-btn>
                        </v-col>
                        <v-col v-else class="flex-wrap">
                            <span class="font-weight-bold">Attached:</span>

                            <v-btn
                                v-for="file in attached()"
                                :key="file.file"
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1  "
                            >
                                <span
                                    class="text-decoration-underline"
                                    @click="openFilePDF(file.file)"
                                    >{{ file.name }}</span
                                >
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="!resend"
                    :disabled="!valid"
                    :loading="loadingButton"
                    @click="checkPdf"
                    rounded
                    elevation="0"
                    color="primary"
                    class="ma-3"
                >
                    Send
                </v-btn>
                <v-btn
                    v-else
                    :disabled="!valid"
                    :loading="loadingButton"
                    @click="resendPDF"
                    rounded
                    elevation="0"
                    color="primary"
                    class="ma-3"
                >
                    Send
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="validateSent" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="
                            () => {
                                this.validateSent = false
                            }
                        "
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    Warning
                </v-card-title>
                <v-card-text>
                    It is not safe to close this window until the shipment
                    process is finished, are you sure you want to close this
                    window ?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="
                            () => {
                                this.validateSent = false
                            }
                        "
                        rounded
                        elevation="0"
                        color="danger"
                        class="ma-3"
                    >
                        CANCEL
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeWarning"
                        rounded
                        elevation="0"
                        color="primary"
                        class="ma-3"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
<script>
import API from '@/services/api'
import { storage } from '@/services/firebase'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'SendQuote',
    props: {
        value: Boolean,
        quote: Object,
        client: Object,
        costs: Array,
        commercialTermsToPDF: String,
        resend: {
            type: Boolean,
            default() {
                return false
            },
        },
        docsBySend: {
            type: Array,
            default() {
                return []
            },
        },
    },
    components: {},
    data() {
        return {
            valid: false,
            contactsToPDF: [],
            mainContacts: [],
            folderDocuments: 'quotes_documents',
            usersToPDF: [],
            nameFile: null,
            email: {},
            url: null,
            generatePdf: false,
            file: null,
            folderQuotes: 'quotes_documents',
            companyId: JSON.parse(localStorage.getItem('company')),
            emailsTo: [],
            emailsCc: [],
            quoteFiles: [],
            fileName: '',
            path: null,
            x: 0,
            search: null,
            y: 0,
            menu: false,
            activator: null,
            attach: null,
            editing: null,
            model: [],
            model1: [],
            editingIndex: -1,
            loading: false,
            error: false,
            errorMsg: null,
            rules: {
                required: v => !!v || 'Required',
            },
            idSettingQuote: null,
            folderPdfImage: 'pdf_images',
            folderClientsPictures: 'clients_pictures',
            loadingButton: false,
            validateSent: false,
            settingsCompany: null,
        }
    },
    computed: {
        ...mapState([
            'commercialTerms',
            'settings',
            'contacts',
            'users',
            'clients',
            'contacts',
        ]),
        ...mapGetters(['getEmailBody']),
        show: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
    watch: {
        quote: async function(newValue) {
            if (!newValue.client) {
                this.quote.client = this.clients.find(
                    client => newValue.clientId == client.id
                )
            }

            if (!this.quote.mainContact) {
                this.quote.mainContact = this.users.find(
                    user => this.quote.userId == user.id
                )
            }

            if (!newValue.docs || !newValue.attached) {
                this.quote.attached = this.quote.files.filter(
                    x => x.attach == true
                )
                this.quote.docs = this.quote.files.filter(
                    x => x.attach == false
                )
            }

            if (newValue && this.loading == false) {
                this.usersToPDF = []
                this.contactsToPDF = []
                this.mainContacts = []
                this.file = null
                this.model1 = []
                this.model = []
                await this.generateReceivers()
                this.fileName =
                    this.quote.name +
                    '-' +
                    (this.quote.number
                        ? this.quote.number
                        : this.quote.shortId) +
                    '.pdf'
            }
        },
        value: async function() {
            if (this.quote) {
                this.contactsToPDF = []
                this.mainContacts = []
                this.model = []
                this.file = null
                await this.generateReceivers()
            }
        },
        model(val, prev) {
            if (val.length === prev.length) return
            this.search = null
            this.model = val.map(v => {
                if (typeof v === 'string' && /.+@.+\..+/.test(v)) {
                    v = {
                        text: v,
                    }
                    this.contactsToPDF.push(v)
                    this.error = false
                } else if (typeof v === 'object') {
                    if (/.+@.+\..+/.test(v.text)) {
                        this.error = false
                    }
                }
                return v
            })
        },
        model1(val, prev) {
            if (val.length === prev.length) return
            this.search = null
            this.model1 = val.map(v => {
                if (typeof v === 'string' && /.+@.+\..+/.test(v)) {
                    v = {
                        text: v,
                    }
                    if (!this.usersToPDF.find(user => user.text == v.text)) {
                        this.usersToPDF.push(v)
                    }
                    this.error = false
                } else if (typeof v === 'object') {
                    if (/.+@.+\..+/.test(v.text)) {
                        this.error = false
                    }
                }
                return v
            })
        },
    },
    methods: {
        ...mapActions([
            'addTermsAction',
            'addUsersAction',
            'addImagesAction',
            'addContactsAction',
        ]),
        ...mapMutations(['setEmailBody', 'setErrorItems']),
        attached() {
            let arr = this.docsBySend.slice()
            arr.pop()
            return arr
        },
        async openFilePDF(file) {
            if (file) {
                await API.getPreviewPDF(this.quote.id)
                await this.$emit('openFilePDF', 'preview.pdf')
            }
        },
        closeDialog() {
            if (this.loading) {
                this.validateSent = true
            } else {
                this.file = null
                this.generatePdf = false
                this.email.text = ''
                this.model1 = []
                this.model = []
                this.$emit('closeDialog')
            }
        },
        closeWarning() {
            this.validateSent = false
            this.loading = false
            this.loadingButton = false
            this.closeDialog()
        },
        async generateAndLoading() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = ''
                await this.openFilePDF(this.fileName)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        edit(index, item) {
            if (!this.editing) {
                this.editing = item
                this.editingIndex = index
            } else {
                this.editing = null
                this.editingIndex = -1
            }
        },
        openFile: async function(file) {
            const id = this.quote.id
            const path = `${this.companyId}/${this.folderDocuments}/${id}`
            const storageRef = storage().ref(path)
            const documentRef = storageRef.child(file)
            const url = await documentRef.getDownloadURL()
            if (this.$device.ios) {
                window.location.assign(url)
            } else {
                window.open(url, '_blank')
            }
        },
        filter(item, queryText, itemText) {
            if (item.header) return false
            const hasValue = val => (val != null ? val : '')
            const text = hasValue(itemText)
            const query = hasValue(queryText)

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            )
        },
        stringToHTML(str) {
            var parser = new DOMParser()
            var doc = parser.parseFromString(str, 'text/html')
            return doc.body.innerHTML
        },
        closePdf() {
            this.generatePdf = false
        },
        async checkPdf() {
            try {
                this.loadingButton = true
                this.loading = true
                await this.sendEmail()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingButton = false
                this.loading = false
            }
        },
        saveFile: async function() {
            try {
                this.$emit('saveCostHistory')
                if (this.quote.files) {
                    this.quoteFiles = this.quote.files
                }
                const path = `${this.companyId}/${this.folderQuotes}/${this.quote.id}`
                this.path = `${path}/${this.quote.name +
                    '-' +
                    this.quote.number +
                    '.pdf'}`
                const newQuote = {}
                const doc = {
                    name: this.fileName,
                    file: this.quote.name + '-' + this.quote.number + '.pdf',
                    versionQuote: true,
                }
                this.quoteFiles.push(doc)
                newQuote.files = this.quoteFiles.slice()
                newQuote.status = 'SENT'
                this.quote.status = 'SENT'
                this.quote.files = this.quoteFiles.slice()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        onFocusFile() {
            if (this.$device.ios) {
                window.location.assign(this.url)
            } else {
                window.open(this.url, 'popup', 'width=800px,height=800px')
            }
        },
        sendEmail: async function() {
            try {
                this.loading = true
                await this.saveFile()
                this.model.forEach(element => {
                    if (typeof element === 'object') {
                        this.emailsTo.push(element.text)
                    }
                })
                if (this.model1.length > 0) {
                    this.model1.forEach(element => {
                        if (typeof element === 'object') {
                            this.emailsCc.push(element.text)
                        }
                    })
                }

                // add main contact
                if (!this.emailsCc.includes(this.quote.mainContact.email)) {
                    this.emailsCc.push(this.quote.mainContact.email)
                }

                const files = this.quote.attached.map(x => {
                    const path = `${this.companyId}/${this.folderQuotes}/${this.quote.id}`
                    const fullpath = `${path}/${x.file}`
                    return {
                        name: x.name,
                        path: fullpath,
                    }
                })
                files.splice(0, 0, { name: this.fileName, path: this.path })

                await API.sendEmail({
                    subject: `${this.quote.name.toUpperCase()}`,
                    to: this.emailsTo,
                    text: this.email.text,
                    emailBody:
                        this.getEmailBody.length > 0
                            ? this.quote.client.language === 'English'
                                ? this.getEmailBody[0].body
                                : this.getEmailBody[1].body
                            : '',
                    cc: this.emailsCc,
                    quoteId: this.quote.id,
                    attach: files,
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                })

                //set follow up date in one month
                if (this.quote.id) {
                    this.chatInfo = await API.getChatInfo(this.quote.id)
                    // set follow up date
                    this.chatInfo.followUpDate = moment()
                        .add(1, 'months')
                        .format('YYYY-MM-DD')
                    await API.updateChatFromQuote({
                        quoteId: this.chatInfo.id,
                        followUpDate: this.chatInfo.followUpDate,
                    }).catch(error => {
                        console.log('chat error')
                        error && true
                    })
                }
                await API.loadPreDesigns(this.quote.id)
                this.$emit('getHistoryCosts')
                this.$emit('activateSuccessAlert')
                this.closeDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.loadingButton = false
                this.usersToPDF = []
                this.contactsToPDF = []
                this.mainContacts = []
                this.file = null
                this.email.text = ''
                this.generatePdf = false
                this.emailsTo = []
                this.emailsCc = []
            }
        },

        async resendPDF() {
            try {
                this.loading = true
                this.model.forEach(element => {
                    if (typeof element === 'object') {
                        this.emailsTo.push(element.text)
                    }
                })
                if (this.model1.length > 0) {
                    this.model1.forEach(element => {
                        if (typeof element === 'object') {
                            this.emailsCc.push(element.text)
                        }
                    })
                }

                // add main contact
                if (!this.emailsCc.includes(this.quote.mainContact.email)) {
                    this.emailsCc.push(this.quote.mainContact.email)
                }

                const files = this.docsBySend.map(x => {
                    const path = `${this.companyId}/${this.folderQuotes}/${this.quote.id}`
                    const fullpath = `${path}/${x.file ? x.file : x}`
                    return {
                        name: x.name ? x.name : x,
                        path: fullpath,
                    }
                })
                let oldQuote = files.pop()
                files.splice(0, 0, oldQuote)
                await API.sendEmail({
                    subject: `${this.quote.name.toUpperCase()}`,
                    to: this.emailsTo,
                    text: this.email.text,
                    cc: this.emailsCc,
                    quoteId: this.quote.id,
                    attach: files,
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.loadingButton = false
                this.usersToPDF = []
                this.contactsToPDF = []
                this.mainContacts = []
                this.file = null
                this.email.text = ''
                this.generatePdf = false
                this.$emit('closeDialog')
                this.emailsTo = []
                this.emailsCc = []
            }
        },

        getUsers: async function() {
            try {
                this.error = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { users },
                } = await API.getUsers(this.companyId)
                for (const user of users) {
                    user.createdOn = moment
                        .unix(user.createdOn._seconds)
                        .format('MMM/DD/YYYY HH:mm')
                }
                this.addUsersAction(users)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        getPdfImages: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.settings.find(u => u.name === 'Quotes')
                const {
                    data: { pdfImages },
                } = await API.getPdfImages(id)

                for (let image of pdfImages[0].images) {
                    if (image.file && image.defaultImage) {
                        const path = `${this.companyId}/${this.folderPdfImage}`
                        const storageRef = storage().ref(path)
                        const profilePicRef = storageRef.child(image.file)
                        await profilePicRef
                            .getDownloadURL()
                            .then(url => {
                                image.imageURL = url
                            })
                            .catch(error => {
                                image.imageURL = ''
                                error && true
                            })
                        this.quote.image = image
                    }
                }
                this.addImagesAction(pdfImages[0])
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        async generateReceivers() {
            try {
                this.error = false
                this.errorMsg = null
                this.email.subject = this.quote.name + ' Quote'
                this.contactsToPDF = []
                this.contactsToPDF[0] = { header: 'Select or create one' }
                const users = []

                if (!this.quote.image) {
                    await this.getPdfImages()
                }
                if (
                    this.quote.contacts &&
                    this.quote.dataContacts?.length === 0
                ) {
                    this.quote.dataContacts = []
                    this.quote.contacts.forEach(element => {
                        const dataContact = this.contacts.find(
                            contact => element == contact.id
                        )
                        if (dataContact) {
                            this.quote.dataContacts.push(dataContact)
                        }
                    })
                }
                if (this.quote.dataContacts) {
                    this.quote.dataContacts.forEach(element => {
                        if (!this.model.find(c => c.text === element.email)) {
                            this.model.push({ text: element.email })
                        }
                    })
                }

                if (!this.users.length || this.users.length === 0) {
                    await this.getUsers()
                }

                if (this.quote.collaborators) {
                    this.quote.dataCollaborators = []
                    this.quote.collaborators.forEach(element => {
                        const dataUser = this.users.find(
                            user => element == user.id
                        )
                        this.quote.dataCollaborators.push(dataUser)
                    })
                }
                this.model1 = []
                if (this.quote.dataCollaborators) {
                    this.quote.dataCollaborators.forEach(element => {
                        this.model1.push({ text: element.email })
                    })
                }
                //
                this.quote.client?.contacts?.forEach(contact => {
                    users.push({ text: contact.email })
                })
                this.users?.forEach(user => {
                    if (!users.find(item => item.text === user.email)) {
                        users.push({ text: user.email })
                    }
                })

                //copy emails from settings
                const { generalInfo } = this.settings.find(
                    u => u.name === 'Company'
                )
                if (generalInfo.emails) {
                    generalInfo.emails.forEach(email => {
                        const current = this.model1.find(
                            element => element.text === email
                        )
                        if (!current) {
                            this.model1.push({ text: email })
                        }
                    })
                }
                this.usersToPDF = this.contactsToPDF.concat(users)
                if (this.usersToPDF.length > 0) {
                    if (this.quote.contacts && this.quote.contacts.length > 0) {
                        this.quote.contacts.forEach(c => {
                            const contact = this.quote.client?.contacts.find(
                                contact => contact.id == c
                            )
                            if (contact) {
                                const current = this.model.find(
                                    element => element.text === contact.email
                                )
                                if (!current) {
                                    this.model.push({ text: contact.email })
                                }
                            }
                        })
                    }
                }

                if (this.quote.userId) {
                    this.quote.mainContact = this.users.find(
                        user => this.quote.userId == user.id
                    )
                }

                if (this.quote.contacts && this.quote.dataContacts) {
                    this.quote.contacts.forEach(element => {
                        this.quote.dataContacts.forEach(contact => {
                            if (element == contact.id) {
                                this.mainContacts.push({
                                    name: contact.name,
                                    email: contact.email,
                                })
                            }
                        })
                    })
                }
                this.email.to = this.mainContacts
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },

        async getCommercialTerms() {
            try {
                this.error = false
                this.errorMsg = null
                const {
                    data: { commercialTerms },
                } = await API.getCommercialTerms(this.idSettingQuote)
                await this.addTermsAction(commercialTerms)
                if (!this.quote.client) {
                    const {
                        data: { clients },
                    } = await API.getClient({ clientId: this.quote.clientId })
                    this.quote.client = clients
                }

                let settingCommercial

                if (this.quote.client.language == 'English') {
                    settingCommercial = this.commercialTerms.find(
                        x => x.id == 'english'
                    )
                } else {
                    settingCommercial = this.commercialTerms.find(
                        x => x.id == 'spanish'
                    )
                }
                if (settingCommercial.terms) {
                    this.quote.commercialTerms = settingCommercial.terms
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        loadEmailBody: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.settings.find(s => s.name == 'Quotes')
                const {
                    data: { emailBody },
                } = await API.getEmailBody(id)
                if (emailBody) {
                    this.setEmailBody(emailBody)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async mounted() {
        this.usersToPDF = []
        this.contactsToPDF = []
        this.mainContacts = []
        this.file = null
        this.fileName =
            this.quote.name +
            '-' +
            (this.quote.number ? this.quote.number : this.quote.shortId) +
            '.pdf'
        await this.loadEmailBody()
        const company = this.settings.filter(x => x.name == 'Company')
        this.settingsCompany = company[0]
    },
    async created() {
        try {
            this.loading = true
            this.error = false
            this.errorMsg = ''
            if (!this.quote.client) {
                this.quote.client = this.clients.find(
                    client => this.quote.clientId == client.id
                )
            }

            if (!this.quote.docs || !this.quote.attached) {
                this.quote.attached = this.quote.files.filter(
                    x => x.attach == true
                )
                this.quote.docs = this.quote.files.filter(
                    x => x.attach == false
                )
            }

            this.fileName =
                this.quote.name +
                '-' +
                (this.quote.number ? this.quote.number : this.quote.shortId) +
                '.pdf'
            await this.generateReceivers()
            if (
                !this.quote.dataContacts ||
                this.quote.dataContacts.length === 0
            ) {
                this.quote.dataContacts = []
                this.quote.contacts?.forEach(element => {
                    const dataContact = this.contacts.find(
                        contact => element == contact.id
                    )
                    if (dataContact) {
                        this.quote.dataContacts.push(dataContact)
                    }
                })
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
}
</script>
